
import { defineComponent, ref } from 'vue';
import Form from '@/view/pages/common/news/Form.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'news-create',
  components: {
    Form,
  },
  setup() {
    const router = useRouter();
    const resultCreate = ref<boolean>(false);

    const changeCreateResult = result => {
      if (result) {
        router.push({
          name: 'news',
        });
      }
    };

    return {
      changeCreateResult,
      resultCreate,
    };
  },
});
